import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default function useShepherd() {
    const router = useRouter();
    const shepherdJS = 'https://cdn.jsdelivr.net/npm/shepherd.js@10.0.1/dist/js/shepherd.min.js';
    const shepherdCSS = 'https://cdn.jsdelivr.net/npm/shepherd.js@10.0.1/dist/css/shepherd.css';

    let shepherdTour = ref(null);

    onMounted(() => {
        if (window.location.href.indexOf('?tour=1') > -1) {

            if(document.querySelector('.shepherd-element')){
                return {};
            }

            let script = document.createElement('script');
            script.src = shepherdJS;
            script.onload = () => {
                let link = document.createElement('link');
                link.href = shepherdCSS;
                link.rel = 'stylesheet';
                document.head.append(link);

                shepherdTour.value = new Shepherd.Tour({
                    useModalOverlay: true,
                    exitOnEsc: false,
                    defaultStepOptions: {
                        classes: 'shadow-md bg-purple-950',
                        scrollTo: true,
                        when: {
                            show: () => {
                                if (shepherdTour.value.getCurrentStep().options.route && router.currentRoute.value.path !== shepherdTour.value.getCurrentStep().options.route) {
                                    router.push(shepherdTour.value.getCurrentStep().options.route);
                                }
                            }
                        }
                    }
                });

                // Define your steps with route options
                shepherdTour.value.addSteps([
                    {
                        id: 'step1',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Welcome To Kong!</h2>
                            <p class="leading-relaxed">This short walkthrough will show you the key features to help get you started.</p>
                        `,
                        route: '/dashboard',  // Assuming this is the path for the home page
                        buttons: [
                            {
                                text: 'Exit Tour',
                                class: 'bg-purple-600',
                                action: shepherdTour.value.complete
                            },
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ]
                    },
                    {
                        id: 'step2',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Collapsable Nav</h2>
                            <p class="leading-relaxed">
                                Clicking the arrow icon here will collapse the nav if you don't have much screen space available.
                            </p>
                        `,
                        attachTo: {
                            element: 'aside #collapseSidebar',
                            on: 'top'
                        },
                        classes: '!top-4',
                        route: '/dashboard',  // Assuming this is the path for the home page
                        buttons: [
                            {
                                text: 'Previous',
                                action: shepherdTour.value.back
                            },
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ]
                    },
                    {
                        id: 'step3',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Resources</h2>
                            <p class="leading-relaxed">
                                Here you will see the main resources you have available to generate.
                            </p>
                        `,
                        route: '/dashboard',  // Assuming this is the path for the home page\
                        attachTo: {
                            element: 'aside #sidenav',
                            on: 'right'
                        },
                        classes: '!left-4',
                        buttons: [
                            {
                                text: 'Previous',
                                action: shepherdTour.value.back
                            },
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ],
                    },
                    {
                        id: 'step4',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Dream Buyer Avatars</h2>
                            <p class="leading-relaxed">
                                The Dream Buyer Avatar generator lets you create the ideal customer for your product or service - their hopes, dreams and fears.
                            </p>
                        `,
                        route: '/dream-buyers',  // Assuming this is the path for the home page\
                        attachTo: {
                            element: 'aside #dreamBuyerLink',
                            on: 'right'
                        },
                        classes: '!left-4',
                        buttons: [
                            {
                                text: 'Previous',
                                action: shepherdTour.value.back
                            },
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ]
                    },
                    {
                        id: 'step5',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Facebook Ad Generator</h2>
                            <p class="leading-relaxed">
                                The Facebook Ad generator lets you create several variations of ad copy for your product or service, in addition to a long list of headline variants.
                            </p>
                        `,
                        route: '/facebook-ads',  // Assuming this is the path for the home page\
                        attachTo: {
                            element: 'aside #facebookAdLink',
                            on: 'right'
                        },
                        classes: '!left-4',
                        buttons: [
                            {
                                text: 'Previous',
                                action: shepherdTour.value.back
                            },
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ]
                    },
                    {
                        id: 'step6',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Direct Response Headline</h2>
                            <p class="leading-relaxed">
                                The Direct Response Headline generator lets you create variations of eyebrows, headlines and subheadlines, designed for your product or service's landing pages.
                            </p>
                        `,
                        route: '/headlines',  // Assuming this is the path for the home page\
                        attachTo: {
                            element: 'aside #headlineLink',
                            on: 'right'
                        },
                        classes: '!left-4',
                        buttons: [
                            {
                                text: 'Previous',
                                action: shepherdTour.value.back
                            },
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ]
                    },
                    {
                        id: 'step7',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">HVCO Titles</h2>
                            <p class="leading-relaxed">
                                The HVCO Title generator lets you create variations of titles for high value content offers, such as ebooks or offer stacks.
                            </p>
                        `,
                        route: '/hvcos',  // Assuming this is the path for the home page\
                        attachTo: {
                            element: 'aside #hvcoLink',
                            on: 'right'
                        },
                        classes: '!left-4',
                        buttons: [
                            {
                                text: 'Previous',
                                action: shepherdTour.value.back
                            },
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ]
                    },
                    {
                        id: 'step8',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Products</h2>
                            <p class="leading-relaxed mb-3">
                                All resources require a product in order to generate them.
                            </p>
                            <p class="leading-relaxed">
                                You can create your first product by going to the Products item in the nav.
                            </p>
                        `,
                        route: '/products',  // Assuming this is the path for the home page
                        attachTo: {
                            element: 'aside #productsLink',
                            on: 'right'
                        },
                        classes: '!left-4',
                        buttons: [
                            {
                                text: 'Previous',
                                action: shepherdTour.value.back
                            },
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ]
                    },
                    {
                        id: 'step9',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Account</h2>
                            <p class="leading-relaxed mb-3">
                                The Account menu item will take you to management page where you can change your password if required. 
                            </p>
                            <p class="leading-relaxed mb-3">
                                Clicking log out will log you out of Kong immediately.
                            </p>
                        `,
                        route: '/dashboard',  // Assuming this is the path for the home page
                        attachTo: {
                            element: 'aside #auxMenu',
                            on: 'top-end'
                        },
                        classes: '!mb-4',
                        buttons: [
                            {
                                text: 'Previous',
                                action: shepherdTour.value.back
                            },
                            {
                                text: 'Next',
                                action: async function(){
                                    await navigateTo('/products/create/');

                                    setTimeout(function(){
                                        shepherdTour.value.next();
                                    }, 100);
                                }
                            }
                        ]
                    },
                    {
                        id: 'step10',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Create Your First Product</h2>
                            <p class="leading-relaxed mb-3">
                                Lets get you started by creating your first product.
                            </p>
                            <p class="leading-relaxed">
                                Fill out your product/service's name, category and a short description in the form, then click "Create Product". 
                            </p>
                        `,
                        attachTo: {
                            element: '#createProductForm',
                            on: 'left'
                        },
                        classes: '!right-4',
                        advanceOn: { selector: '#createProductForm', event: 'shepherdEvent' },
                        buttons: [
                            {
                                text: 'Prev',
                                action: shepherdTour.value.back
                            }
                        ]
                    },
                    {
                        id: 'step11',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Nicely Done!</h2>
                            <p class="leading-relaxed mb-3">
                                Your Products can be edited from the product listing page later on if need be.
                            </p>
                            <p class="leading-relaxed mb-3">
                                Lastly, we'll quickly show you how to generate resources then you're free to explore!
                            </p>
                        `,
                        route: '/products',  // Assuming this is the path for the home page
                        buttons: [
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ]
                    },
                    {
                        id: 'step12',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Creating Resources</h2>
                            <p class="leading-relaxed mb-3">
                                The interface for creating new resources is fairly standard across the board.
                            </p>
                            <p class="leading-relaxed mb-3">
                                For now, we'll just look at creating a new dream buyer.
                            </p>
                        `,
                        buttons: [
                            {
                                text: 'Prev',
                                action: shepherdTour.value.prev
                            },
                            {
                                text: 'Next',
                                action: shepherdTour.value.next
                            }
                        ]
                    },
                    {
                        id: 'step13',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Create A Dream Buyer</h2>
                            <p class="leading-relaxed mb-3">
                                To create a dream buyer for your new product, click the "Create New Avatar" button on your new product.
                            </p>
                        `,
                        attachTo: {
                            element: '.new-avatar-button',
                            on: 'left-end'
                        },
                        classes: '!right-4',
                        advanceOn: { selector: 'body', event: 'shepherdDreamBuyerForm' },
                        buttons: [
                            {
                                text: 'Prev',
                                action: shepherdTour.value.prev
                            }
                        ]
                    },
                    {
                        id: 'step14',
                        text: `
                            <h2 class="text-xl font-semibold mb-3">Create A Dream Buyer</h2>
                            <p class="leading-relaxed mb-3">
                                Fill out the form here as best as you can, then click "Create Dream Buyer".
                            </p>
                            <p class="leading-relaxed mb-3">
                                Some resources, including dream buyers, can take between 6-10 minutes to generate.
                            </p>
                            <p class="leading-relaxed mb-3">
                                This wraps up the tour, happy marketing!
                            </p>
                        `,
                        buttons: [
                            {
                                text: 'Prev',
                                action: shepherdTour.value.prev
                            },
                            {
                                text: 'End Tour',
                                action: shepherdTour.value.complete
                            }
                        ]
                    },
                ]);
            };
            document.head.append(script);
        }
    });

    return {
        shepherdTour
    };
}
